import React, { useState, useEffect } from 'react';
import { getMembers } from './api/calls';
import { authInstance } from './config/firebase';
import Loading from './components/Loading/Loading';
import Login from './pages/Login/Login';
import AppNavBar from './components/AppNavBar/AppNavBar';
import MembersListForManager from './pages/MembersListForManager/MembersListForManager';
import MembersListForWorker from './pages/MembersListForWorker/MembersListForWorker';
import './App.css';



// 600000000000543524 = "Tennis Only"
// 600000000000543518 = "Member"
// 600000000000543523 = "Trade Member"
// 600000000000543521 = "Senior Member - A"
// 600000000000543517 = "Sponsor Member"
// 600000000000543516 = "Past Member"
// 600000000000543519 = "Other"

const omitListForWorkers = ['600000000000543516','600000000000543519'];
//const omitListForWorkers = [];


function App() {
    const [appLoading, setAppLoading] = useState(true);
    const [auth, setAuth] = useState(null);
    const [userType, setUserType] = useState(null);
    const [members, setMembers] = useState(null);

    const signOutHandler = () => {
        authInstance().signOut();
    };

    useEffect(() => {
        authInstance().onAuthStateChanged(user => {
            if (user) {
                setAuth(true);
                switch(user.email){
                    case "frontdesk@pmcc.site" :
                    case "worker@app.com" :
                        setUserType("worker")
                        break;
                    case "manager@pmcc.site" :
                    case "manager@app.com" :
                        setUserType("manager")
                        break;
                }
                
               
                setAppLoading(false);
                getMembers()
                    .then(data => setMembers(data))
                    .catch(err => setMembers([]));
                return;
            }

            setUserType(null);
            setAuth(false);
            setAppLoading(false);
        });
    }, []);

    return (
        <div className="App">
            <Loading value={appLoading} />

            { (!auth && auth !== null) && <Login setLoading={setAppLoading} /> }

            { auth && (
                <div>
                    <AppNavBar userType={userType} logout={signOutHandler} />
                    { userType === "manager" && <MembersListForManager members={members} /> }
                    { userType === "worker" && <MembersListForWorker omitList={omitListForWorkers} members={members} /> }
                </div>
            )}

        </div>
    );
}

export default App;
