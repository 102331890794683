import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import FormLabel from 'react-bootstrap/FormLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import styles from './ActionBar.module.scss';

const ActionBar = ({ searchKeywords, onChangeSearch, entriesCount, onChangeEntriesCount, sortType, onChangeSortType, userType }) => {
    return (
        <div className={styles.Wrapper}>
            <div className={styles.Item}>
                <FormLabel>Show entries</FormLabel>
                <FormControl
                    className={styles.Count}
                    type="number"
                    value={entriesCount}
                    onChange={e => onChangeEntriesCount(e.target.value)}
                />
            </div>

            { userType === "manager" && (
                <div className={[styles.Item, styles.SortContainer].join(' ')}>
                    <FormLabel>Sort by</FormLabel>
                    <select value={sortType}
                            onChange={e => onChangeSortType(e.target.value)}
                            className={["form-control", styles.SortBy].join(' ')}>
                        <option value="" disabled>Select</option>
                        <option value="name a-z">Name A-Z</option>
                        <option value="name z-a">Name Z-A</option>
                        <option value="email a-z">Email A-Z</option>
                        <option value="email z-a">Email Z-A</option>
                        <option value="type a-z">Type A-Z</option>
                        <option value="type z-a">Type Z-A</option>
                        <option value="balance ascending">Balance ascending</option>
                        <option value="balance descending">Balance descending</option>
                    </select>
                </div>
            )}

            <div className={styles.Item}>
                <InputGroup>
                    <InputGroup.Text className={styles.Prepend}>
                        <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <FormControl
                        value={searchKeywords}
                        onChange={e => onChangeSearch(e.target.value)}
                        placeholder="Search"
                    />
                </InputGroup>
            </div>
        </div>
    );
};

export default ActionBar;
