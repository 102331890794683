import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import styles from './AppNavBar.module.scss';

const AppNavBar = ({ userType, logout }) => {
    return (
        <Navbar bg="light" expand="lg" className={styles.Navbar}>
            <Container>
                <h3>Viewing as {userType}</h3>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Button className={styles.LogoutBtn} variant="link" onClick={logout}>
                            <FontAwesomeIcon icon={faSignOutAlt} /> Log out
                        </Button>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default AppNavBar;
