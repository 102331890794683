import axios from "./api";

export const getMembers = async () => {
  try {
    const response = await axios.get("/members");
    return response.data;
  } catch (err) {
    console.log("err", err);
    return err;
  }
};

export const checkInMember = async (member) => {
  // Call check in API here.

  try {
    const response = await axios.post("/checkin", { member: member.PK });
    //console.log(`Response ${response}`);
    //console.log(`Checked in ${member.PK}`);
    return response.data;
  } catch (err) {
    console.log("err", err);
    return err;
  }
};

export const listCheckins = async (member) => {
  // Call check in API here.

  try {
    const response = await axios.get("/checkin",{params: { id: member.PK }});
    //console.log(`Response ${response}`);
    //console.log(`Checked in ${member.PK}`);
    return response.data;
  } catch (err) {
    console.log("err", err);
    return err;
  }
};

