import React, {useState} from 'react';
import {checkInMember} from '../../api/calls';
import Container from 'react-bootstrap/Container';
import ActionBar from '../../components/ActionBar/ActionBar';
import MembersTable from '../../components/MembersTable/MembersTable';
import CheckInModal from '../../components/CheckInModal/CheckInModal';
import styles from './MembersListForManager.module.scss';

const MembersListForManager = ({ members }) => {
    const [search, setSearch] = useState("");
    const [entriesCount, setEntriesCount] = useState(50);
    const [sortType, setSortType] = useState("name a-z");
    const [showModal, setShowModal] = useState(false);
    const [selectedMember, setSelectedMember] = useState(null);

    return (
        <Container className={styles.Wrapper}>
            <h2 className="text-center mb-3">Members List</h2>

            <ActionBar searchKeywords={search}
                       userType="manager"
                       onChangeSearch={setSearch}
                       sortType={sortType}
                       onChangeSortType={setSortType}
                       entriesCount={entriesCount}
                       onChangeEntriesCount={setEntriesCount} />

            <MembersTable data={members}
                          userType="manager"
                          emailFilter={true}
                          keywords={search}
                          sortType={sortType}
                          entriesCount={entriesCount}
                          showModal={() => setShowModal(true)}
                          setSelectedMember={setSelectedMember} />

            <CheckInModal show={showModal}
                          userType="manager"
                          member={selectedMember}
                          onCheckIn={checkInMember}
                          onHide={() => setShowModal(false)} />
        </Container>
    );
};

export default MembersListForManager;
