import React, { useState, useEffect } from 'react';
import { checkInMember } from '../../api/calls';
import Container from 'react-bootstrap/Container';
import MembersTable from '../../components/MembersTable/MembersTable';
import ActionBar from '../../components/ActionBar/ActionBar';
import CheckInModal from '../../components/CheckInModal/CheckInModal';
import styles from './MembersListForWorker.module.scss';

const MembersListForWorker = ({ members, omitList }) => {
    const [search, setSearch] = useState("");
    const [updatedMembers, setUpdatedMembers] = useState([]);
    const [entriesCount, setEntriesCount] = useState(200);
    const [sortType, setSortType] = useState("name a-z");
    const [showModal, setShowModal] = useState(false);
    const [selectedMember, setSelectedMember] = useState(null);

    useEffect(() => {
        if (members?.length) {
            const data = [...members].filter(member => {
                const val = member.customerType.toString();
                return omitList.indexOf(val) < 0;
            });
            setUpdatedMembers(data);
        }
    }, [members, omitList]);

    return (
        <Container className={styles.Wrapper}>
            <h2 className="text-center mb-3">Members List</h2>

            <ActionBar searchKeywords={search}
                       onChangeSearch={setSearch}
                       sortType={sortType}
                       entriesCount={entriesCount}
                       onChangeEntriesCount={setEntriesCount} />

            <MembersTable data={updatedMembers}
                          keywords={search}
                          emailFilter={false}
                          entriesCount={entriesCount}
                          sortType={sortType}
                          showModal={() => setShowModal(true)}
                          setSelectedMember={setSelectedMember} />

            <CheckInModal show={showModal}
                          member={selectedMember}
                          onCheckIn={checkInMember}
                          onHide={() => setShowModal(false)} />
        </Container>
    );
};

export default MembersListForWorker;
