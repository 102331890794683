import React, { useState } from 'react';
import { authInstance } from '../../config/firebase';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import styles from './Login.module.scss';

const Login = ({ setLoading }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const loginSubmitHandler = (e) => {
        e.preventDefault();

        setLoading(true);
        authInstance().signInWithEmailAndPassword(email, password)
            .then(user => {
                setLoading(false);
                setLoading("");
            })
            .catch(err => {
                setLoading(false);
                setError(err.message);
            })
    };

    return (
        <Container className={styles.Wrapper}>
            <h1 className={styles.Heading}>Login</h1>
            <Form className={styles.Form} onSubmit={loginSubmitHandler}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" onChange={e => setEmail(e.target.value)}/>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                </Form.Group>
                <Button variant="primary" type="submit">
                    Submit
                </Button>

                <div className="mt-2">{ error && <small className="text-danger">{ error }</small> }</div>
            </Form>
        </Container>
    );
};

export default Login;
